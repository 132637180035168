/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    code: "code",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Box, Container, Stack, Heading, Text, Lead} = _components;
  if (!Box) _missingMdxReference("Box", true);
  if (!Container) _missingMdxReference("Container", true);
  if (!Heading) _missingMdxReference("Heading", true);
  if (!Lead) _missingMdxReference("Lead", true);
  if (!Stack) _missingMdxReference("Stack", true);
  if (!Text) _missingMdxReference("Text", true);
  return React.createElement(React.Fragment, null, React.createElement(Box, {
    layerStyle: "pageTitle"
  }, React.createElement(Container, {
    maxW: "container.lg"
  }, React.createElement(Stack, {
    align: "center",
    justify: "space-between",
    direction: ["column", "column", "row"],
    mb: 2
  }, React.createElement(Heading, {
    as: "h1",
    size: "xl"
  }, "Changelog"), "\n", React.createElement(Text, null, "Version 1.0.1")), React.createElement(Lead, {
    maxW: {
      base: "full",
      md: "540px"
    },
    textAlign: {
      base: "center",
      md: "start"
    }
  }, React.createElement(_components.p, null, "All notable changes to this project will be documented in this file. Visit ", React.createElement(_components.a, {
    href: "https://themeforest.net/user/themebiotic",
    rel: "nofollow noopener noreferrer"
  }, "Themebiotic Profile"), " for more details.")))), "\n", React.createElement(Container, {
    mb: [10, 14, 20],
    sx: {
      "*": {
        fontFamily: "mono"
      }
    }
  }, React.createElement(Stack, {
    layerStyle: "changelog"
  }, React.createElement(_components.h2, {
    id: "101-2023-04-26"
  }, React.createElement(_components.a, {
    href: "#101-2023-04-26"
  }, "1.0.1 ", React.createElement(_components.code, null, "(2023-04-26)"))), React.createElement(_components.h3, {
    id: "bug-fixes"
  }, React.createElement(_components.a, {
    href: "#bug-fixes"
  }, "Bug Fixes")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "set siteUrl as an environment variable for improved functionality"), "\n"), React.createElement(_components.h2, {
    id: "100-2023-02-20"
  }, React.createElement(_components.a, {
    href: "#100-2023-02-20"
  }, "1.0.0 ", React.createElement(_components.code, null, "(2023-02-20)"))), React.createElement(_components.p, null, "Initial release published"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
